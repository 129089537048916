import React from "react";
import "./HomePage.css";
import "./NosProgrammes.css";

import image_preparation_physique from '../assets/preparation_physique.jpeg';
import image_remise_en_forme from '../assets/remise_forme.jpeg';
import image_sport_sante from '../assets/gallery2.jpeg';
import image_suivi_psychologique from '../assets/coach_diego.jpeg';

const titleCoachStyle = {fontSize:'clamp(16px, 2vw, 30px)', fontWeight:'bold', marginBottom:20, color:'#004AAD', textAlign:'center'};
const descriptionCoachStyle = {fontSize:'clamp(12px, 1vw, 22px)'};

export default function NosProgrammes() {


    return (
        <div className="home">
            <div className='home-square-background'>
                <section 
                    style={{
                        display:'flex', 
                        width:'100%', 
                        alignItems:'center',
                        flexDirection:'column', 
                        paddingLeft:20, 
                        paddingRight:20
                    }}
                >
                    <h2 style={{textAlign:'center', fontSize:30}}>Nos programmes</h2>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            alignItems:'center',
                            width:'100%', 
                        }}
                        id="program-preparation-physique"
                    >
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                alignItems:'flex-start'
                            }}
                        >
                            <div
                                style={{
                                    width:'33%',
                                    display:'flex',
                                    alignItems:'center', 
                                    justifyContent:'center'
                                }}
                            >
                                <img 
                                    src={image_preparation_physique} alt="Remise en forme" 
                                    style={{
                                        borderRadius:50,
                                        width:'80%',
                                        objectFit:'cover'
                                    }} 
                                    
                                />
                            </div>
                            <div className='containerPresentationProgram'>		
                                <p style={titleCoachStyle}>
                                    Préparation physique
                                </p> 
                                <p
                                    style={descriptionCoachStyle}
                                >
                                    Nos séances de préparation physique chez Arche Sport sont spécialement conçues pour s’adapter aux besoins spécifiques de chaque adhérent. 
                                    Que vous soyez athlète de haut niveau ou intermédiaire, nos coachs vous proposent des programmes personnalisés en fonction de vos objectifs : renforcement musculaire, amélioration de l’endurance, préparation à une compétition. Nous adaptons également les exercices en fonction de votre discipline sportive. 
                                    <br/><br/>Chaque mouvement est pensé pour se rapprocher au maximum des gestes et exigences de votre sport, afin de vous aider à optimiser vos performances dans votre pratique. Cela garantit une préparation physique ciblée, favorisant des progrès concrets et applicables directement à votre activité sportive. 
                                    <br/><br/>Chaque séance inclut des exercices variés, comme des travaux de force, des circuits fonctionnels, et du cardio, en fonction de vos demandes, pour garantir des progrès efficaces et mesurables.
                                </p>			
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            alignItems:'center',
                            width:'100%', 
                            marginTop:40
                        }}
                        id="program-remise-forme"
                    >
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                alignItems:'flex-start'
                            }}
                        >
                            <div className='containerPresentationProgram'>		
                                <p style={titleCoachStyle}>
                                    Remise en forme
                                </p> 
                                <p
                                    style={descriptionCoachStyle}
                                >
Nos séances de remise en forme chez Arche Sport sont conçues pour vous aider à retrouver votre énergie, améliorer votre condition physique et vous sentir mieux au quotidien. Que vous soyez débutant ou que vous souhaitiez reprendre une activité physique après une pause, nos coachs élaborent des programmes personnalisés en fonction de vos besoins et objectifs.
<br/><br/>
Chaque séance combine des exercices variés alliant renforcement musculaire, cardio et mobilité, avec un accent mis sur l’adaptation de la difficulté en fonction de votre niveau et de vos demandes. Que vous cherchiez à tonifier votre corps, améliorer votre endurance ou simplement retrouver la forme, nos coachs ajusteront l'intensité pour garantir des résultats progressifs et adaptés.
<br/><br/>
Dans une ambiance conviviale et familiale, nos coachs vous accompagnent avec bienveillance pour vous motiver à chaque étape de votre parcours. Reprenez confiance en vous et votre corps grâce à nos séances de remise en forme adaptées à tous.
                                </p>			
                            </div>
                            <div
                                style={{
                                    width:'33%',
                                    display:'flex',
                                    alignItems:'center', 
                                    justifyContent:'center'
                                }}
                            >
                                <img 
                                    src={image_remise_en_forme} alt="Remise en forme" 
                                    style={{
                                        borderRadius:50,
                                        width:'80%',
                                        objectFit:'cover'
                                    }} 
                                    
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            alignItems:'center',
                            width:'100%', 
                            marginTop:40
                        }}
                        id="program-sport-sante"
                    >
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                alignItems:'flex-start'
                            }}
                        >
                            <div
                                style={{
                                    width:'33%',
                                    display:'flex',
                                    alignItems:'center', 
                                    justifyContent:'center'
                                }}
                            >
                                <img 
                                    src={image_sport_sante} alt="Remise en forme" 
                                    style={{
                                        borderRadius:50,
                                        width:'80%',
                                        objectFit:'cover'
                                    }} 
                                    
                                />
                            </div>
                            <div className='containerPresentationProgram'>		
                                <p style={titleCoachStyle}>
                                    Sport Santé
                                </p> 
                                <p
                                    style={descriptionCoachStyle}
                                >
                                    Les séances de sport santé proposées par Arche Sport sont spécifiquement conçues pour répondre aux recommandations médicales en matière d'activité physique adaptée, notamment pour les personnes ayant reçu une prescription de sport santé par leur médecin. 
                                    <br/>Que vous soyez en rééducation, que vous ayez une pathologie chronique, ou que vous souhaitiez simplement améliorer votre santé générale, nos coachs certifiés sont là pour vous accompagner.
                                    <br/><br/>Nos séances sont élaborées en fonction de votre état de santé et des objectifs médicaux fixés. L’entraînement comprend du cardio modéré pour améliorer l'endurance et renforcer le système cardiovasculaire sans surcharger l’organisme, ainsi que des exercices de renforcement musculaire doux, améliorer la posture et maintenir la mobilité. De plus, des exercices de mobilité et de souplesse sont intégrés pour travailler l'équilibre, réduire les douleurs articulaires et prévenir les chutes.
                                    <br/><br/>En tant que salle à taille humaine, Arche Sport offre un suivi personnalisé et une approche bienveillante, garantissant que chaque adhérent progresse à son rythme en fonction des recommandations médicales.
                                </p>			
                            </div>
                        </div>
                    </div>
                    <div
                        style={{
                            display:'flex',
                            flexDirection:'column',
                            alignItems:'center',
                            width:'100%', 
                            marginTop:40
                        }}
                        id="program-fitmind"
                    >
                        <div
                            style={{
                                display:'flex',
                                flexDirection:'row',
                                alignItems:'flex-start'
                            }}
                        >
                            <div className='containerPresentationProgram'>		
                                <p style={titleCoachStyle}>
                                    FITMIND - Allier Sport, Psychologie et Bien-être
                                </p> 
                                <p
                                    style={descriptionCoachStyle}
                                >
                                    Le programme FitMind proposé par Arche Sport est une approche innovante et complète qui combine à la fois coaching sportif et accompagnement psychologique. Conçu pour ceux qui cherchent non seulement à atteindre leurs objectifs physiques, mais aussi à surmonter les obstacles mentaux qui peuvent freiner leur progression, FitMind vous aide à transformer votre corps et votre esprit.
                                    <br/><br/>Chez Arche Sport, nous croyons que la réussite d’un programme de perte de poids repose autant sur le mental que sur le physique. C’est pourquoi FitMind se concentre sur l’amélioration de votre condition physique tout en abordant les aspects psychologiques liés à la motivation, à la gestion du stress, et aux habitudes de vie. Ce programme s’adresse à tous ceux qui souhaitent perdre du poids de manière durable, et adopter un mode de vie plus sain, tout en travaillant sur leur bien-être mental.
                                    <br/><br/>Le programme FitMind repose sur une combinaison de séances sportives adaptées à votre niveau et à vos objectifs, et d’un accompagnement psychologique pour vous aider à comprendre et surmonter les blocages qui pourraient empêcher votre progression. Vous bénéficiez d’un suivi encadré par un coach certifié en psychologie et des coachs certifiés en sport, ce qui vous assure un accompagnement de qualité, tant sur le plan physique que mental.
                                    <br/><br/>Chaque semaine, vous participerez à 4 séances structurées pour maximiser vos résultats :
                                    <br/><br/>•	3 séances de groupe : Ces séances collectives incluent un mélange de musculation, exercices fonctionnels et circuit training en haute intensité. Elles sont conçues pour renforcer votre corps, améliorer votre endurance, et vous motiver grâce à l’énergie du groupe.
                                    <br/><br/>•	1 séance privée : Vous bénéficierez d’une séance cardio à basse intensité, qui est également un moment d’échange privilégié avec votre coach. Cette séance permet d’allier l’effort physique à un travail sur le mental, offrant un espace de discussion pour aborder vos défis, motivations et objectifs personnels.
                                    <br/><br/>Tout au long du programme FitMind, nous réalisons des tests sportifs réguliers afin d’évaluer vos progrès et d’ajuster vos séances en fonction de votre évolution. Vous bénéficierez également de conseils nutritionnels personnalisés pour soutenir vos efforts physiques, et un contact constant avec votre coach, qui reste disponible pour répondre à vos questions, vous motiver et vous guider.
FitMind ne se contente pas de vous aider à améliorer votre condition physique, il vous accompagne dans un véritable changement de mode de vie. Ce programme allie sport et accompagnement psychologique pour vous offrir les outils nécessaires à une transformation durable, tant sur le plan mental que physique.
                                </p>			
                            </div>
                            <div
                                style={{
                                    width:'33%',
                                    display:'flex',
                                    alignItems:'center', 
                                    justifyContent:'center'
                                }}
                            >
                                <img 
                                    src={image_suivi_psychologique} alt="Remise en forme" 
                                    style={{
                                        borderRadius:50,
                                        width:'80%',
                                        objectFit:'cover'
                                    }} 
                                    
                                />
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
