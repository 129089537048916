import React from "react";

import { FaInstagram } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FiMail } from "react-icons/fi";



export default function ContactUs() {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const handleOpenGmail = () => {
        if (isMobile) {
          // Opens Gmail or default mail app
          window.location.href = "mailto:larchesportif@gmail.com";
        } else {
          // Fallback to web-based Gmail
         // window.open("https://mail.google.com/", "_blank");
        }
      };
    
      const handleOpenWhatsApp = () => {
        if (isMobile) {
          // Opens WhatsApp if installed
          window.location.href = "whatsapp://send?phone=+33619830341";
        } else {
          // Fallback to WhatsApp Web
          //window.open("https://web.whatsapp.com/", "_blank");
        }
      };
    
      const handleOpenInstagram = () => {
        if (isMobile) {
          // Opens Instagram app
          window.location.href = "instagram://user?username=arche_sport";
        } else {
          // Fallback to Instagram website
          window.open("https://www.instagram.com/arche_sport", "_blank");
        }
      };

  return (
    <div style={{display:'flex', flexDirection:'row', width:'100%', justifyContent: 'space-evenly', marginTop:10}}>
        <div style={{display:'flex', flexDirection:'column', width:'30%', alignItems:'center'}}
            onClick={handleOpenGmail}>
            <FiMail size={'clamp(30px, 5vw, 50px)'} color={'#DB4437'} />
            <p style={{fontSize:'clamp(12px, 1vw, 26px)', margin:0, width:'100%', display:'flex', textAlign:'center'
            }}>larchesportif@gmail.com</p>
        </div>
        <div style={{display:'flex', flexDirection:'column', width:'30%', alignItems:'center',
            cursor: 'pointer'
        }}
            onClick={handleOpenInstagram}>
            <FaInstagram size={'clamp(30px, 5vw, 50px)'} color={'#E1306C'} />
            <p style={{fontSize:'clamp(12px, 1vw, 26px)', margin:0}}>@arche_sport</p>
        </div>
        <div style={{display:'flex', flexDirection:'column', width:'30%', alignItems:'center'}}
        onClick={handleOpenWhatsApp}>
            <FaWhatsapp size={'clamp(30px, 5vw, 50px)'} color={'#25D366'} />
            <p style={{fontSize:'clamp(12px, 1vw, 26px)', margin:0}}> 
              06 19 83 03 41
            </p>
        </div>
    </div>
  );
}