import React from "react";
import Slider from "react-slick";

import "./GymImagesPresentation.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import salle_1 from '../assets/presentation_salle_1.jpeg';
import salle_2 from '../assets/presentation_salle_2.jpeg';
import salle_3 from '../assets/presentation_salle_3.jpeg';
import salle_4 from '../assets/presentation_salle_4.jpeg';
import salle_5 from '../assets/presentation_salle_5.jpeg';

import VideoPlayer from "./VideoPlayer";

export default function GymImagesPresentation() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  
  return (
    <div className="gym-slider-container"> {/* Updated class for container */}
      <Slider {...settings}>
        <div className="video-container">
          <VideoPlayer />
        </div>
        <div className="image-container">
          <img src={salle_1} alt="" className="img-slider" />
        </div>
        <div className="image-container">
          <img src={salle_2} alt="" className="img-slider" />
        </div>
        <div className="image-container">
          <img src={salle_3} alt="" className="img-slider" />
        </div>
        <div className="image-container">
          <img src={salle_4} alt="" className="img-slider" />
        </div>
        <div className="image-container">
          <img src={salle_5} alt="" className="img-slider" />
        </div>
      </Slider>
    </div>
  );
}
