import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import './NavBar.css';
import { IoIosMenu } from "react-icons/io";
import { RxCross2 } from "react-icons/rx";


const NavBar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
      document.documentElement.style.overflow = '';

      document.body.style.overflowX = 'hidden';
      document.documentElement.style.overflowX = 'hidden';
    }

    // Cleanup function to remove the class when component unmounts
    return () => {
      document.body.style.overflow = '';
      document.documentElement.style.overflow = '';

      document.body.style.overflowX = 'hidden';
      document.documentElement.style.overflowX = 'hidden';
    };
  }, [isOpen]);

  return (
    <nav className="navbar">
      <div className="nav-logo">
        ARCHE SPORT
      </div>
      <div className={`nav-links ${isOpen ? 'open' : ''}`}>
        <Link to="/" className='home_menu_button' onClick={() => setIsOpen(false)}>Accueil</Link>
        <Link to="/localisation" className='home_menu_button' onClick={() => setIsOpen(false)}>Localisation</Link>
        <Link to="/programs" className='home_menu_button' onClick={() => setIsOpen(false)}>Nos programmes</Link>
        <Link to="/tarifs" className='home_menu_button' onClick={() => setIsOpen(false)}>Tarifs et horaires</Link>
        <Link to="/gallery" className='home_menu_button' onClick={() => setIsOpen(false)}>Galerie</Link>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        {isOpen ? (
          <RxCross2 color={'white'} size={28} />
        ) : (
          <IoIosMenu color={'white'} size={28}  />
        )}
      </div>
    </nav>
  );
};

export default NavBar;
