import React from 'react';

import { Gallery } from "react-grid-gallery";

import image_gallery_1 from '../assets/gallery1.jpeg';
import image_gallery_2 from '../assets/gallery2.jpeg';
import image_gallery_3 from '../assets/gallery3.jpeg';
import image_gallery_4 from '../assets/gallery4.jpeg';
import image_gallery_5 from '../assets/gallery5.jpeg';
import image_gallery_6 from '../assets/gallery6.jpeg';
import image_gallery_7 from '../assets/gallery7.jpeg';
import image_gallery_8 from '../assets/gallery8.jpeg';
import image_gallery_9 from '../assets/gallery9.jpeg';
import image_gallery_10 from '../assets/gallery10.jpeg';
import image_gallery_11 from '../assets/gallery11.jpeg';
import image_gallery_12 from '../assets/gallery12.jpeg';
import image_gallery_13 from '../assets/gallery13.jpeg';
import image_gallery_14 from '../assets/gallery14.jpeg';
import image_gallery_15 from '../assets/gallery15.jpeg';
import image_gallery_16 from '../assets/gallery16.jpeg';
import image_gallery_17 from '../assets/gallery17.jpeg';
import image_gallery_18 from '../assets/gallery18.jpeg';
import image_gallery_19 from '../assets/gallery19.jpeg';
import image_gallery_20 from '../assets/gallery20.jpeg';
import image_gallery_21 from '../assets/gallery21.jpeg';
import image_gallery_22 from '../assets/gallery22.jpeg';
import image_gallery_23 from '../assets/gallery23.jpeg';
import image_gallery_24 from '../assets/gallery24.jpeg';
import image_gallery_25 from '../assets/gallery25.jpeg';
import image_gallery_26 from '../assets/gallery26.jpeg';
import image_gallery_27 from '../assets/gallery27.jpeg';
import image_gallery_28 from '../assets/gallery28.jpeg';
import image_gallery_29 from '../assets/gallery29.jpeg';

import "./HomePage.css";

const galleryImages = [
  {
     src: image_gallery_1,
     width: 1536,
     height: 2040
  },
  {
    src: image_gallery_2,
    width: 1536,
    height: 2040
 },
 {
  src: image_gallery_3,
  width: 1377,
  height: 919
},
{
  src: image_gallery_4,
  width: 1536,
  height: 2040
},
{
  src: image_gallery_5,
  width: 4080,
  height: 3072
},
{
  src: image_gallery_6,
  width: 3072,
  height: 4080
},
{
  src: image_gallery_7,
  width: 1600,
  height: 1200
},
{
  src: image_gallery_8,
  width: 1600,
  height: 1200
},
{
  src: image_gallery_9,
  width: 1600,
  height: 1200
},
{
  src: image_gallery_10,
  width: 1600,
  height: 1200
},
{
  src: image_gallery_11,
  width: 1600,
  height: 1200
},
{
  src: image_gallery_12,
  width: 1200,
  height: 1600
},
{
  src: image_gallery_13,
  width: 1600,
  height: 1200
},
{
  src: image_gallery_14,
  width: 1200,
  height: 1600
},
{
  src: image_gallery_15,
  width: 3264,
  height: 2448
},
{
  src: image_gallery_16,
  width: 3264,
  height: 2448
},
{
  src: image_gallery_17,
  width: 3264,
  height: 2448
},
{
  src: image_gallery_18,
  width: 3264,
  height: 2448
},
{
  src: image_gallery_19,
  width: 3264,
  height: 2448
},
{
  src: image_gallery_20,
  width: 1200,
  height: 1600
},
{
  src: image_gallery_21,
  width: 1543,
  height: 2048
},
{
  src: image_gallery_22,
  width: 2048,
  height: 1536
},
{
  src: image_gallery_23,
  width: 1828,
  height: 2048
},
{
  src: image_gallery_24,
  width: 1543,
  height: 2048
},
{
  src: image_gallery_25,
  width: 1440,
  height: 1440
},
{
  src: image_gallery_26,
  width: 4080,
  height: 3072
},
{
  src: image_gallery_27,
  width: 4080,
  height: 3072
},
{
  src: image_gallery_28,
  width: 3072,
  height: 4080
},
{
  src: image_gallery_29,
  width: 1204,
  height: 1600
}
];

const GalleryPage = () => {
  return (
    <div className="home">
      <div className='home-square-background'>
        <section style={{width:'80%', alignItems:'center', flexDirection:'column'}}>
            <h2 style={{textAlign:'center', fontSize:30}}>Galerie</h2>
                <Gallery images={galleryImages} enableImageSelection={false} rowHeight={400} />
        </section>
      </div>
    </div>
  );
};

export default GalleryPage;
