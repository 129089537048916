import React from "react";
import "./Localisation.css";

import { FaTrainTram } from "react-icons/fa6";
import { FaBus } from "react-icons/fa6";
import { SiGooglemaps } from "react-icons/si";

import localisationImg2 from '../assets/localisation_2.JPG';

const containerLogoOurServicesStyle = {display:'flex', padding:10, borderRadius:50, backgroundColor:'white', 
    alignItems:'center', justifyContent:'center', boxShadow: '0px 0px 10px rgba(128, 128, 128, 0.2)',
    marginRight:10
  }

export default function Localisation() {
    return (
        <div className="home">
            <div className='home-square-background'>
                <section style={{width:'100%', alignItems:'center', flexDirection:'column', paddingLeft:20, paddingRight:20}}>
                    <h2 style={{textAlign:'center', fontSize:'clamp(22px, 4vw, 40px)'}}>Localisation</h2>
                    <div style={{marginTop:50, display:'flex', flexDirection:'row', width:'100%', justifyContent:'space-evenly'}}>
                        <div style={{flex:1}}>
                            <p style={{fontWeight:'bold', fontSize:'clamp(18px, 4vw, 40px)'}}>Adresse</p>
                            <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                <div>
                                    <div style={containerLogoOurServicesStyle}>
                                        <FaBus size={'clamp(22px, 4vw, 40px)'} color={"#004AAD"} />
                                    </div>
                                </div>
                                <p style={{fontSize:'clamp(18px, 4vw, 40px)', marginLeft:20}}>51 Avenue de Rueil, 92000 Nanterre</p>
                            </div>
                            <p style={{fontWeight:'bold', fontSize:'clamp(18px, 4vw, 40px)'}}>Transports</p>
                            <div style={{width:'100%', display:'flex', flexDirection:'column'}}>
                                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                    <div>
                                        <div style={containerLogoOurServicesStyle}>
                                            <FaTrainTram size={'clamp(22px, 4vw, 40px)'} color={"#004AAD"} />
                                        </div>
                                    </div>
                                    <p style={{fontSize:'clamp(18px, 4vw, 40px)', marginLeft:20}}>RER A: gare de Nanterre-Ville ou Rueil-Malmaison</p>
                                </div>
                                <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}>
                                    <div>
                                        <div style={containerLogoOurServicesStyle}>
                                            <FaBus size={'clamp(22px, 4vw, 40px)'} color={"#004AAD"} />
                                        </div>
                                    </div>
                                    <p style={{fontSize:'clamp(18px, 4vw, 40px)', marginLeft:20}}>Bus: 158, 258, 259</p>
                                </div>
                            </div>
                            
                        </div>
                        <div
                            style={{alignItems:'center', display:'flex', flexDirection:'column'}}
                        >
                            <div>
                                <div style={{borderRadius:50, overflow:'hidden'}}>
                                    <img src={localisationImg2} style={{width:'100%'}} />
                                </div>
                            </div>
                            <div
                                style={{
                                    display:'flex',
                                    flexDirection:'column',
                                    alignItems:'center',
                                    width:'80%',
                                    marginTop:10
                                }}
                            >
                                <a 
                                    href="https://www.google.com/search?q=Arche+Sport+nanterre&sca_esv=553650bb8dd64e30&rlz=1C1CHZN_frFR930FR936&ei=Tl2qZva0OvKlkdUPiYW5mA4&ved=0ahUKEwj2k5jy0NGHAxXyUqQEHYlCDuMQ4dUDCBA&uact=5&oq=Arche+Sport+nanterre&gs_lp=Egxnd3Mtd2l6LXNlcnAiFEFyY2hlIFNwb3J0IG5hbnRlcnJlMgUQABiABDICECYyCBAAGIAEGKIEMggQABiABBiiBDIIEAAYgAQYogQyCBAAGIAEGKIEMggQABiiBBiJBUjIDVCTAljmDHABeAGQAQCYAU2gAcwEqgEBObgBA8gBAPgBAZgCCqAC5QTCAgoQABiwAxjWBBhHwgIGEAAYFhgewgIIEAAYFhgKGB7CAgoQABgWGAoYHhgPwgIIEAAYFhgeGA-YAwCIBgGQBgiSBwIxMKAHlSc&sclient=gws-wiz-serp" 
                                    target="_blank" 
                                    rel="noopener noreferrer"
                                    className="link-button-google-maps-page"
                                >
                                    <SiGooglemaps size={'clamp(30px, 5vw, 30px)'} />
                                    <p style={{fontSize:'clamp(16px, 2vw, 30px)', marginTop:0, marginBottom:0, marginLeft:10}}>
                                        Page Google Maps
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}
