import React, { useEffect, useRef, useState } from 'react';
import './VideoPlayer.css';

const VideoPlayer = () => {
  const videoRef = useRef(null);
  //const [isPlaying, setIsPlaying] = useState(false);
  //const [isMuted, setIsMuted] = useState(true);

  useEffect(() => {
    const playVideoOnInteraction = () => {
      if (videoRef.current) {
        videoRef.current.play();
      }
    };

    window.addEventListener('click', playVideoOnInteraction);

    return () => {
      window.removeEventListener('click', playVideoOnInteraction);
    };
  }, []);

  /*const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleMuteUnmute = () => {
    videoRef.current.muted = !isMuted;
    setIsMuted(!isMuted);
  };*/

  return (
    <div className="video-container">
      <video
        ref={videoRef}
        muted
        loop
        autoPlay
        playsInline
        className="video-element"
      >
        <source src={`${process.env.PUBLIC_URL}/salle_presentation.mp4`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoPlayer;
