import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import Tarification from './components/Tarification';
import Localisation from './components/Localisation';
import BecomeMember from './components/BecomeMember';
import NosProgrammes from './components/NosProgrammes';
import GalleryPage from './components/Gallery';
import NavBar from './components/NavBar';

import './App.css';

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/localisation" element={<Localisation />} />
        <Route path="/programs" element={<NosProgrammes />} />
        <Route path="/tarifs" element={<Tarification />} />
        <Route path="/gallery" element={<GalleryPage />} />
      </Routes>     
    </Router>
  );
}

export default App;
