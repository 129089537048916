import React from "react";
import "./HomePage.css";

import './Tarification.css';

import horaires from '../assets/horaires.jpeg';


const titleBlockStyle = {fontWeight:'bold', color:'white', fontSize:'clamp(16px, 4vw, 30px)', textAlign:'center'};
const textPriceStyle = {fontWeight:'bold', fontSize:'clamp(14px, 2vw, 16px)'};

export default function Tarification() {
    const firstColor = '#d43546';
    const secondColor = '#004aad';
    const thirdColor = '#863d6c';
    // const fourthColor = '#434f73';

    return (
        <div className="home">
            <div className='home-square-background'>
                <section style={{width:'96%', display:'flex', alignItems:'center', flexDirection:'column',}}>
                    <h2 style={{textAlign:'center', fontSize:30}}>Tarifs</h2>
                    <div className="container-tarification">
                        <div className="container-type-tarif">
                            <div>
                                <div
                                    style={{
                                        width:'100%', 
                                        height:'20%', 
                                        display:'flex', 
                                        backgroundColor:firstColor,
                                        alignItems:'center',
                                        justifyContent:'center'
                                    }}
                                >
                                    <p style={titleBlockStyle}>Coaching</p>
                                </div>
                                <div
                                    style={{
                                        width:'100%',
                                        alignItems:'center',
                                        display:'flex',
                                        flexDirection:'column'
                                    }}
                                >
                                    <div
                                        style={{
                                            display:'flex',
                                            flexDirection:'row',
                                            alignItems:'center',
                                            width:'100%',
                                            height:40,
                                            paddingLeft:20,
                                            paddingRight:20
                                        }}
                                    >
                                        <div 
                                            style={{
                                                width:'100%', 
                                                height:2, 
                                                borderRadius:50, 
                                                backgroundColor: firstColor,
                                                marginRight: 5
                                            }}
                                        />
                                        <p style={{color: firstColor, fontWeight:'bold', fontSize:'clamp(14px, 2vw, 16px)'}}>Privé</p>
                                        <div 
                                            style={{
                                                width:'100%', 
                                                height:2, 
                                                borderRadius:50, 
                                                backgroundColor: firstColor,
                                                marginLeft: 5
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display:'flex',
                                            flexDirection:'row',
                                            alignItems:'center',
                                            justifyContent:'space-between',
                                            width:'100%',
                                            marginTop:-10,
                                            paddingLeft:20,
                                            paddingRight:20
                                        }}
                                    >
                                        <p style={textPriceStyle}>1 séance</p>
                                        <p style={textPriceStyle}>60€</p>
                                    </div>
                                    <div
                                        style={{
                                            display:'flex',
                                            flexDirection:'row',
                                            alignItems:'center',
                                            justifyContent:'space-between',
                                            width:'100%',
                                            marginTop:-10,
                                            paddingLeft:20,
                                            paddingRight:20
                                        }}
                                    >
                                        <p style={textPriceStyle}>1 séance domicile proche Nanterre</p>
                                        <p style={textPriceStyle}>75€</p>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width:'100%',
                                        alignItems:'center',
                                        display:'flex',
                                        flexDirection:'column'
                                    }}
                                >
                                    <div
                                        style={{
                                            display:'flex',
                                            flexDirection:'row',
                                            alignItems:'center',
                                            width:'100%',
                                            height:40,
                                            paddingLeft:20,
                                            paddingRight:20
                                        }}
                                    >
                                        <div 
                                            style={{
                                                display:'flex',
                                                flex:1,
                                                width:'100%', 
                                                height:2, 
                                                borderRadius:50, 
                                                backgroundColor: firstColor,
                                                marginRight: 5
                                            }}
                                        />
                                        <p style={{color: firstColor, fontWeight:'bold', fontSize:'clamp(14px, 2vw, 16px)'}}>
                                            Groupe 3/4 personnes
                                        </p>
                                        <div 
                                            style={{
                                                display:'flex',
                                                flex:1,
                                                height:2, 
                                                borderRadius:50, 
                                                backgroundColor: firstColor,
                                                marginLeft: 5
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display:'flex',
                                            flexDirection:'row',
                                            alignItems:'center',
                                            justifyContent:'space-between',
                                            width:'100%',
                                            marginTop:-10,
                                            paddingLeft:20,
                                            paddingRight:20
                                        }}
                                    >
                                        <p style={textPriceStyle}>10 séances</p>
                                        <p style={textPriceStyle}>250€</p>
                                    </div>
                                    <div
                                        style={{
                                            display:'flex',
                                            flexDirection:'row',
                                            alignItems:'center',
                                            justifyContent:'space-between',
                                            width:'100%',
                                            marginTop:-10,
                                            paddingLeft:20,
                                            paddingRight:20
                                        }}
                                    >
                                        <p style={textPriceStyle}>16 séances</p>
                                        <p style={textPriceStyle}>370€</p>
                                    </div>
                                    <div
                                        style={{
                                            display:'flex',
                                            flexDirection:'row',
                                            alignItems:'center',
                                            justifyContent:'space-between',
                                            width:'100%',
                                            marginTop:-10,
                                            paddingLeft:20,
                                            paddingRight:20
                                        }}
                                    >
                                        <p style={textPriceStyle}>Mensuel*, 2 séances par semaine</p>
                                        <p style={textPriceStyle}>180€ / mois</p>
                                    </div>
                                    <div
                                        style={{
                                            display:'flex',
                                            flexDirection:'row',
                                            alignItems:'center',
                                            justifyContent:'space-between',
                                            width:'100%',
                                            marginTop:-10,
                                            paddingLeft:20,
                                            paddingRight:20
                                        }}
                                    >
                                        <p style={textPriceStyle}>Mensuel*, 3 séances par semaine</p>
                                        <p style={textPriceStyle}>240€ / mois</p>
                                    </div>
                                    <div
                                        style={{
                                            display:'flex',
                                            flexDirection:'row',
                                            alignItems:'center',
                                            justifyContent:'space-between',
                                            width:'100%',
                                            marginTop:-10,
                                            paddingLeft:20,
                                            paddingRight:20
                                        }}
                                    >
                                        <p style={textPriceStyle}>Annuel**, 2/3 séances par semaine</p>
                                        <p style={textPriceStyle}>1980€ / an</p>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width:'100%',
                                        marginTop:20,
                                        display:'flex',
                                        flexDirection:'column',
                                        paddingLeft:20,
                                        paddingRight:20
                                    }}
                                >
                                    <p style={{padding:0, margin:0, fontSize:'clamp(14px, 2vw, 16px)'}}><b>*</b> engagement 6 mois minimum</p>
                                </div>
                                <div
                                    style={{
                                        width:'100%',
                                        display:'flex',
                                        marginBottom:10,
                                        flexDirection:'column',
                                        paddingLeft:20,
                                        paddingRight:20
                                    }}
                                >
                                    <p style={{padding:0, margin:0, fontSize:'clamp(14px, 2vw, 16px)'}}><b>**</b> sauf le mois d'août</p>
                                </div>
                            </div>
                        </div>
                        <div className="container-type-tarif-2">
                            <div 
                                style={{
                                    width:'100%',
                                    height:'40%', 
                                    display:'flex',
                                    borderRadius:'2vw',
                                    overflow:'hidden',
                                    boxShadow:'0px 0px 10px rgba(128, 128, 128, 0.2)',
                                    flexDirection:'column'
                                }}
                            >
                                <div
                                    style={{
                                        width:'100%', 
                                        height:'30%', 
                                        display:'flex', 
                                        backgroundColor:secondColor,
                                        alignItems:'center',
                                        justifyContent:'center'
                                    }}
                                >
                                    <p style={titleBlockStyle}>Sport santé</p>
                                </div>
                                <div
                                    style={{
                                        width:'100%',
                                        alignItems:'center',
                                        display:'flex',
                                        flexDirection:'column'
                                    }}
                                >
                                    <div
                                        style={{
                                            display:'flex',
                                            flexDirection:'row',
                                            alignItems:'center',
                                            width:'100%',
                                            height:40,
                                            paddingLeft:20,
                                            paddingRight:20
                                        }}
                                    >
                                        <div 
                                            style={{
                                                width:'100%', 
                                                height:2, 
                                                borderRadius:50, 
                                                backgroundColor: secondColor,
                                                marginRight: 5
                                            }}
                                        />
                                        <p style={{color: secondColor, fontWeight:'bold', textAlign:'center',
                                            fontSize:'clamp(14px, 2vw, 16px)'
                                        }}>Max 4 personnes</p>
                                        <div 
                                            style={{
                                                width:'100%', 
                                                height:2, 
                                                borderRadius:50, 
                                                backgroundColor: secondColor,
                                                marginLeft: 5
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display:'flex',
                                            flexDirection:'row',
                                            alignItems:'center',
                                            justifyContent:'space-between',
                                            width:'100%',
                                            marginTop:-10,
                                            paddingLeft:20,
                                            paddingRight:20
                                        }}
                                    >
                                        <p style={textPriceStyle}>Mensuel, lundi et jeudi</p>
                                        <p style={textPriceStyle}>140€ / mois</p>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        width:'100%',
                                        marginTop:20,
                                        display:'flex',
                                        flexDirection:'column',
                                        alignItems:'center',
                                        marginBottom:10,
                                        paddingLeft:20,
                                        paddingRight:20
                                    }}
                                >
                                    <p style={{padding:0, margin:0, fontSize:'clamp(14px, 2vw, 16px)'}}>
                                        ordonnance obligatoire
                                    </p>
                                </div>
                            </div>
                            <div 
                                style={{
                                    width:'100%',
                                    height:'20%', 
                                    display:'flex',
                                    borderRadius:'2vw',
                                    overflow:'hidden',
                                    boxShadow:'0px 0px 10px rgba(128, 128, 128, 0.2)',
                                    flexDirection:'column',
                                    marginTop:20
                                }}
                            >
                                <div
                                    style={{
                                        width:'100%', 
                                        height:'40%', 
                                        display:'flex', 
                                        backgroundColor:thirdColor,
                                        alignItems:'center',
                                        justifyContent:'center'
                                    }}
                                >
                                    <p style={titleBlockStyle}>FITMIND</p>
                                </div>
                                <div
                                    style={{
                                        width:'100%',
                                        alignItems:'center',
                                        display:'flex',
                                        flexDirection:'column'
                                    }}
                                >
                                    <div
                                        style={{
                                            display:'flex',
                                            flexDirection:'row',
                                            alignItems:'center',
                                            width:'100%',
                                            height:40,
                                            paddingLeft:20,
                                            paddingRight:20
                                        }}
                                    >
                                        <div 
                                            style={{
                                                width:'100%', 
                                                height:2, 
                                                borderRadius:50, 
                                                backgroundColor: thirdColor,
                                                marginRight: 5
                                            }}
                                        />
                                        <p style={{color: thirdColor, fontWeight:'bold', fontSize:'clamp(14px, 2vw, 16px)'}}>Privé</p>
                                        <div 
                                            style={{
                                                width:'100%', 
                                                height:2, 
                                                borderRadius:50, 
                                                backgroundColor: thirdColor,
                                                marginLeft: 5
                                            }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            display:'flex',
                                            flexDirection:'row',
                                            alignItems:'center',
                                            justifyContent:'space-between',
                                            width:'100%',
                                            marginTop:-10,
                                            paddingLeft:20,
                                            paddingRight:20
                                        }}
                                    >
                                        <p style={textPriceStyle}>Mensuel, 4 séances par semaine</p>
                                        <p style={textPriceStyle}>560€ / mois</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        style={{width:'100%', marginTop:20, paddingLeft:20, paddingRight:20, alignItems:'center',
                            display:'flex', flexDirection:'column'
                        }}
                    >
                        <p style={{fontSize:30, marginBottom:0, marginTop:0, fontWeight:'bold'}}>
                            Horaires
                        </p>
                        <img 
                            src={horaires} alt="horaires" 
                            className="horaires-img"
                        />
                    </div>
                </section>
            </div>
        </div>
    );
}
