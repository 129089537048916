import React from "react";
import Slider from "react-slick";

import "./AdherentsComments.css";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function AdherentsComments() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <Slider {...settings} style={{width: "100%", height: "100%", display: 'flex', marginTop:20, marginBottom:50}}>
        <div >
            <div className="image-container-adherent">
                <p className="image-container-p">"Une salle qui a changé ma vie. Des coachs aux petits soins pour leurs clients. Un suivi personnalisé et des résultats visibles. Quoi dire de plus. Prêt pour la prochaine saison !" - Hugo</p>
            </div>
        </div>
        <div>
            <div className="image-container-adherent">
                <p className="image-container-p">"Belle salle pour tout le monde ! Des coachs compétents et qualifiés ! Tout sa dans une ambiance familliale et professionnel." - Antonio</p>
            </div>
        </div>
        <div>
            <div className="image-container-adherent">
                <p className="image-container-p">"Angela & Noé sont des préparateurs physique de grande qualité : disponible, ouvert, sympathique, à l'écoute, ils sauront comprendre correctement votre besoin et y répondre efficacement !" - Tanguy</p>
            </div>
        </div>
        <div>
            <div className="image-container-adherent">
                <p className="image-container-p">"Belle salle pour tous !
                Autant pour les débutants que pour les professionnels." - Corentin</p>
            </div>
        </div>
    </Slider>
  );
}