import React from 'react';
import './HomePage.css';

import image_preparation_physique from '../assets/preparation_physique.jpeg';
import image_remise_en_forme from '../assets/remise_forme.jpeg';
import image_sport_sante from '../assets/gallery2.jpeg';
import image_suivi_psychologique from '../assets/coach_diego.jpeg';

import img_coach_noe from '../assets/coach_noe.jpeg';
import img_coach_angela from '../assets/coach_angela.jpeg';
import img_coach_diego from '../assets/coach_diego.jpeg'


import GymImagesPresentation from './GymImagesPresentation';
import AdherentsComments from './AdherentsComments';
import ContactUs from './ContactUs';

import { GiDiploma } from "react-icons/gi";
import { MdOutlineSportsMartialArts } from "react-icons/md";
import { FaClock } from "react-icons/fa6";
import { MdLocationOn } from "react-icons/md";
import { FaShower } from "react-icons/fa";
import { PiLockersFill } from "react-icons/pi";
import { MdFamilyRestroom } from "react-icons/md";
import { TbEar } from "react-icons/tb";
import { GiHumanTarget } from "react-icons/gi";

import { useNavigate } from 'react-router-dom';



const imageStyle = {
  display: 'block',
  width: '100%',
  height: '100%',
  objectFit: 'cover'
};

const textStyle = {
  position: 'absolute',
  bottom: 0,
  color: 'white', // Change this based on your background color
  padding: '2%',
  width: '100%', // Optional: adjust based on your design
  textAlign: 'center',
};

const featureItemStyle = {position: 'relative', display: 'flex', width: '30%', height: 400, 
  borderRadius: 50, overflow: 'hidden', boxShadow: '0px 5px 40px rgba(128, 128, 128, 0.2)'}

const featureItemStyle2Left = {position: 'relative', display: 'flex', width: '30%', height: 400, 
    borderRadius: 50, overflow: 'hidden', boxShadow: '0px 5px 40px rgba(128, 128, 128, 0.2)', marginRight:'4%'}


const coachsImageContainerStyle = {position: 'absolute', display: 'inline-block', width: '30%', height: 600, 
    borderRadius: 50, overflow: 'hidden', boxShadow: '0px 5px 40px rgba(128, 128, 128, 0.2)'}

const ourServicesItemStyle = {display:'flex', flexDirection:'column', alignItems:'center', width:'20%'};
const ourServicesTextStyle = {color: '#004AAD', fontWeight: 'bold', fontSize: 'clamp(16px, 1.2vw, 24px)', marginTop:5, textAlign:'center'};
const ourServicesRowStyle = {flexDirection:'row', display: 'flex', justifyContent: 'space-evenly', width: '100%'};
const containerLogoOurServicesStyle = {display:'flex', width:'clamp(40px, 3vw, 200px)', height:'clamp(40px, 3vw, 200px)', borderRadius:50, backgroundColor:'white', 
  alignItems:'center', justifyContent:'center', boxShadow: '0px 0px 20px rgba(128, 128, 128, 0.2)', padding:'5%'
}
const h1Style = {textShadow: '2px 2px 4px rgba(0, 0, 0, 1)', fontSize:'clamp(22px, 4vw, 60px)', textAlign:'center'};
const h1SubStyle = {textShadow: '2px 2px 4px rgba(0, 0, 0, 1)', fontSize:'clamp(22px, 3vw, 60px)', textAlign:'center'};
const h2Style = {fontSize:'clamp(20px, 2vw, 40px)', marginBottom:0, marginTop:0};
const h2SubTextStyle = {fontSize:'clamp(18px, 1.4vw, 36px)', marginTop:0};
const titleCoachStyle = {fontSize:'clamp(18px, 2vw, 30px)', fontWeight:'bold', marginBottom:20, color:'#004AAD', textAlign:'center'};
const descriptionCoachStyle = {fontSize:'clamp(12px, 1vw, 22px)'};


const HomePage = () => {

	const navigate = useNavigate();

	return (
	<div className="home">
    	<div className='home-square-background'>
      		<header className="hero">
				<h1 style={h1Style}>Arche Sport - Nanterre</h1>
				<p style={h1SubStyle}>Salle de Sport privée</p>
      		</header>
        	<section className="features">
				<h2 style={h2Style}>Nos programmes</h2>
				<p style={h2SubTextStyle}>Pour un suivi adapté à chaque pratiquant</p>
          		<div className="features-list">
            		<div className="feature-item-2" onClick={() => navigate('/programs')}>
              			<img src={image_preparation_physique} alt="Preparation physique" style={imageStyle} />
						<div style={textStyle} >
							<p className='text_title_feature_item'>Préparation physique</p>
						</div>              
            		</div>
            		<div className="feature-item-2" onClick={() => navigate('/programs')}>
						<img src={image_remise_en_forme} alt="Remise en forme" style={imageStyle} />
						<div style={textStyle}>
							<p className='text_title_feature_item'>Remise en forme</p>
						</div>
            		</div>
          		</div>
          		<div className="features-list">
					<div className="feature-item-2" onClick={() => navigate('/programs')}>
						<img src={image_sport_sante} alt="Sport sante" style={imageStyle} />
						<div style={textStyle}>
							<p className='text_title_feature_item'>Sport santé</p>
						</div>             
					</div>
            		<div className="feature-item-2" onClick={() => navigate('/programs')}>
						<img src={image_suivi_psychologique} alt="Remise en forme" style={imageStyle} />
						<div style={textStyle}>
							<p className='text_title_feature_item'>Suivi psychologique</p>
						</div>
            		</div>
				</div>
       		</section>
			<section className="video_presentation">
				<h2 style={h2Style}>La salle</h2>
				<GymImagesPresentation />
			</section>
			<section 
				style={{
					display:'flex',
					flexDirection: 'column',
					width:'100%',
					marginTop:60,
					alignItems:'center',
					justifyContent:'center',
				}}
			>
          		<h2 style={h2Style}>Les coachs</h2>
				<div
					style={{
						display:'flex',
						flexDirection:'column',
						alignItems:'center',
						width:'100%', 
					}}
				>
					<div
						style={{
							display:'flex',
							flexDirection:'row',
						}}
					>
						<div
							style={{
								width:'33%',
								display:'flex',
								alignItems:'center', 
								justifyContent:'center'
							}}
						>
							<img 
								src={img_coach_noe} alt="Remise en forme" 
								style={{
									borderRadius:50,
									width:'80%',
									objectFit:'cover'
								}} 
								
							/>
						</div>
						<div className='containerPresentationCoach'>		
							<p style={titleCoachStyle}>
								Noé
							</p> 
							<p
								style={descriptionCoachStyle}
							>
								"Passionné par mon métier, je suis prêt à vous accompagner jusqu'au bout de vous-même afin que vous puissiez réaliser vos objectifs sportifs ! Actuellement pratiquant et compétiteur, j'ai évolué dans différents domaines physiques et sportifs, allant du sport santé en EHPAD à la préparation physique de haut niveau. Mon expérience et ma polyvalence me permettent de vous offrir un accompagnement personnalisé, que vous soyez débutant ou athlète confirmé. Ensemble, nous repousserons vos limites pour atteindre vos objectifs."
							</p>			
						</div>
					</div>
					<div
						style={{
							display:'flex',
							flexDirection:'row',
							justifyContent:'flex-end'
						}}
					>
						<div className='containerPresentationCoach'>		
							<p style={titleCoachStyle}>
								Angela
							</p>
							<p
								style={descriptionCoachStyle}
							>
								"Coach sportif passionnée par le bien-être physique et mental. Aujourd'hui, je partage mes connaissances avec ceux qui ont décidé d'entreprendre ce voyage vers une meilleure santé grâce à l'activité physique. Mon objectif est d'offrir un accompagnement solide et réaliste, en soutenant mes clients face aux défis tels que le manque de confiance, la discipline et la patience, afin de les aider à les surmonter et à atteindre leurs objectifs."
							</p>			
						</div>
						<div
							style={{
								width:'33%',
								display:'flex',
								alignItems:'center', 
								justifyContent:'center'
							}}
						>
							<img 
								src={img_coach_angela} alt="Remise en forme" 
								style={{
									borderRadius:50,
									width:'80%',
									objectFit:'cover'
								}} 
							/>
						</div>
					</div>
					<div
						style={{
							display:'flex',
							flexDirection:'row',
						}}
					>
						<div
							style={{
								width:'33%',
								display:'flex',
								alignItems:'center', 
								justifyContent:'center'
							}}
						>
							<img 
								src={img_coach_diego} alt="Remise en forme" 
								style={{
									borderRadius:50,
									width:'80%',
									objectFit:'cover'
								}} 
								
							/>
						</div>
						<div className='containerPresentationCoach'>		
							<p style={titleCoachStyle}>
								Diego
							</p>
							<p
								style={descriptionCoachStyle}
							>
								"Diplômé en psychologie et passionné par l'accompagnement physique. Aujourd'hui, je combine mes connaissances en sciences cognitives et en psychologie avec le coaching sportif pour aider spécifiquement les personnes souhaitant perdre du poids de manière durable avec le programme FItMind. Mon objectif est d'accompagner mes clients dans leur transformation physique et mentale à travers un suivi personnalisé, en les aidant à adopter des habitudes saines et à atteindre leurs objectifs de perte de poids sur le long terme."
							</p>			
						</div>
					</div>
				</div>
			</section>
			<section 
				style={{
					flexDirection: 'column', 
					width:'100%',
					paddingLeft:20, 
					paddingRight:20, 
					alignItems:'center', 
					display:'flex',
					backgroundColor:'white',
					marginTop:20
				}}
			>
				<h2 style={h2Style}>Nos services</h2>
				<div style={{marginTop:20, marginBottom:20}}>
					<div style={ourServicesRowStyle}>
						<div style={ourServicesItemStyle}>
							<div style={containerLogoOurServicesStyle}>
								<MdLocationOn style={{width:'100%', height:'100%'}} />
							</div>
							<p style={ourServicesTextStyle}>Salle disponible à Nanterre</p>
						</div>
						<div style={ourServicesItemStyle}>
							<div style={containerLogoOurServicesStyle}>
								<FaClock style={{width:'100%', height:'100%'}} />
							</div>
							<p style={ourServicesTextStyle}>Séances 7j/7 <br />entre 6h et 23h</p>
						</div>
						<div style={ourServicesItemStyle}>
							<div style={containerLogoOurServicesStyle}>
								<GiDiploma style={{width:'100%', height:'100%'}} />
							</div>
							<p style={ourServicesTextStyle}>Coachs diplômés</p>
						</div>
					</div>
					<div style={ourServicesRowStyle}>
						<div style={ourServicesItemStyle}>
							<div style={containerLogoOurServicesStyle}>
								<FaShower style={{width:'100%', height:'100%'}} />
							</div>
							<p style={ourServicesTextStyle}>Douches individuelles</p>
						</div>
						<div style={ourServicesItemStyle}>
							<div style={containerLogoOurServicesStyle}>
								<PiLockersFill style={{width:'100%', height:'100%'}} />
							</div>
							<p style={ourServicesTextStyle}>Vestiaires individuels</p>
						</div>
						<div style={ourServicesItemStyle}>
							<div style={containerLogoOurServicesStyle}>
								<MdOutlineSportsMartialArts style={{width:'100%', height:'100%'}} />
							</div>
							<p style={ourServicesTextStyle}>Matériel sportifs et équipement sport de combat</p>
						</div>
					</div>
					<div style={ourServicesRowStyle}>
						<div style={ourServicesItemStyle}>
							<div style={containerLogoOurServicesStyle}>
								<GiHumanTarget style={{width:'100%', height:'100%'}} />
							</div>
							<p style={ourServicesTextStyle}>Programmes personnalisés</p>
						</div>
						<div style={ourServicesItemStyle}>
							<div style={containerLogoOurServicesStyle}>
								<TbEar style={{width:'100%', height:'100%'}} />
							</div>
							<p style={ourServicesTextStyle}>Coachs à l'écoute</p>
						</div>
						<div style={ourServicesItemStyle}>
							<div style={containerLogoOurServicesStyle}>
								<MdFamilyRestroom style={{width:'100%', height:'100%'}} />
							</div>
							<p style={ourServicesTextStyle}>Ambiance familiale</p>
						</div>
					</div>
				</div>
			</section>
			<section className="adherents-comments">
				<h2 style={h2Style}>Ils en parlent mieux que nous</h2>
				<AdherentsComments />
			</section>
			<section className="follow-us">
				<h2 style={h2Style}>Contacte nous !</h2>
				<ContactUs />
			</section>
		</div>
	</div>
  );
};

export default HomePage;
